




















































import { Component, Mixins } from 'vue-property-decorator'
import { brokerageModule } from '@/store/store-accessor'
import { Brokerage } from '../models/brokerage'
import LoadingIcon from '@/components/loading-icon/index.vue'
import LoadableMixin from '@/mixins/loadable-mixin'
import { formatPhone } from '@/helpers/formatters'

@Component({
  name: 'Expired',
  components: {
    LoadingIcon
  }
})

export default class Expired extends Mixins(LoadableMixin) {
  async created () {
    document.title = brokerageModule.brokerage
      ? `${brokerageModule.brokerage.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title
  }

  // Computed getters...
  get brokerage (): Brokerage | null {
    return brokerageModule.brokerage
  }

  get brokerageEmail (): string {
    const contacts = brokerageModule.brokerage?.contacts ?? []
    return contacts.length > 0
      ? contacts[0].email
      : ''
  }

  get brokeragePhone (): string {
    const contacts = brokerageModule.brokerage?.contacts ?? []
    return contacts.length > 0
      ? formatPhone(contacts[0].phone)
      : ''
  }
}
